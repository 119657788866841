import React from 'react'
import { FluidObject } from 'gatsby-image'
import { graphql } from 'gatsby'

import { AcademyQuery } from '../../types/graphql'

import { Button, ButtonToolbar } from '../../components/Button'
import { Container } from '../../components/Container'
import { NewsFeed } from '../../components/NewsFeed'
import { Section } from '../../components/Section'
import { SEO } from '../../components/Seo'
import { Youth } from '../../containers/Youth'

import * as classes from './academy.module.scss'

interface Props {
  data: AcademyQuery
}

export default ({ data }: Props) => {
  return (
    <Youth cover={data.cover?.childImageSharp?.fluid as FluidObject}>
      <SEO title={'Academy'} />

      <Section>
        <Container size={'small'}>
          <p>
            Il progetto Pallacanestro Reggiana Academy nasce nell’estate 2019
            con l’intento di accrescere ulteriormente la rete di contatti e
            collaborazioni con tutte le società del territorio reggiano e non
            solo, con il focus puntato sull’attività giovanile. Per farlo,
            Pallacanestro Reggiana propone un modello collaborativo per avere
            uno scambio ed insieme contribuire alla crescita del movimento.
            Anche se l’obiettivo societario ha la prospettiva di portare i
            ragazzi a diventare professionisti, pensiamo che molti aspetti
            possano essere condivisi con club che fanno un’attività leggermente
            diversa dalla nostra, con finalità più sociali e aggregative.
          </p>
          <ButtonToolbar className={classes.buttonToolbar}>
            <Button
              as={'a'}
              href={data.academyDocument?.localFile?.publicURL!}
              target={'_blank'}
              rel={'noreferrer noopener'}
            >
              Scopri di più
            </Button>

            <Button
              as={'a'}
              href={
                'https://www.youtube.com/playlist?list=PLqWJhh6Ne0rVwbX0njFj5GcZw65Zi7A_2'
              }
              target={'_blank'}
              rel={'noreferrer noopener'}
            >
              Guarda i video
            </Button>
          </ButtonToolbar>
        </Container>
      </Section>

      <Section title={'Ultime news'} withContainer>
        <NewsFeed category={'academy'} preview />
      </Section>
    </Youth>
  )
}

export const query = graphql`
  query Academy {
    cover: file(name: { eq: "academy" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    academyDocument: wordpressWpMedia(slug: { eq: "academy" }) {
      localFile {
        publicURL
      }
    }
  }
`
